<template>
  <div>
    <h2>{{ promo }}</h2>
    <table>
      <tr>
        <td>{{ promo.id }}</td>
        <td>{{ promo.code }}</td>
        <td>{{ promo.type }}</td>
        <td>{{ promo.value }}</td>
        <td>{{ promo.uses }}</td>
        <td>{{ promo.threshold }}</td>
        <td>{{ promo.store_id }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PromoCodes",
  data() {
    return {
      promos: [],
      promo: '',
    }
  },
  props: {
    pcId: String,
  },
  methods: {
    getPromo(){
      axios.get("https://nvrprinting.com/Backend/api/promo/"+ this.pcId)
      .then(data => {
        this.promo = data.data;
      });
    }
  },
  mounted() {
    this.getPromo();
  }
}
</script>
<style scoped>

table,
thead {
  border: 1px solid white;
}

th,
tr,
td{
  padding: 10px;
  text-align: center;
}
</style>
